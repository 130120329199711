.box-home-body {
  display: flex;
  flex-direction: row;
}

.step-pc {
  display: block;
}

.step-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .box-home-body {
    display: flex;
    flex-direction: column;
  }
  .step-pc {
    display: none;
  }

  .step-mobile {
    display: block;
  }
}
